import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './style/init.css'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'animate.css';

const getColumnList = (name) => {
    const menu = JSON.parse(sessionStorage.getItem('menu')) || [];
    const aboutPage = menu.find(item => item.page_url == name);
    if (!aboutPage) return {}
    return aboutPage ? {list: aboutPage.children, title: aboutPage.title || ''} : {
        list: [],
        title: aboutPage.title || ''
    };
}


createApp(App).use(store).use(router).use(VueAxios, axios).use(ElementPlus).provide('getColumnList', getColumnList).mount('#app')
