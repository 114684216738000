import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/index.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/about.vue')
  },
  {
    path: '/signUp',
    name: 'SignUp',
    component: () => import('../views/signUp.vue')
  },
  {
    path: '/model',
    name: 'Model',
    component: () => import('../views/model.vue')
  },
  {
    path: '/life',
    name: 'Life',
    component: () => import('../views/life.vue')
  },
  {
    path: '/work',
    name: 'Work',
    component: () => import('../views/work.vue')
  },
  {
    path: '/dynamic',
    name: 'Dynamic',
    component: () => import('../views/dynamic.vue')
  } , {
    path: '/enrollment',
    name: 'Enrollment',
    component: () => import('../views/enrollment.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
