<template>
  <div class="footer-layout flex flex-col align-center">
    <div class="bg ">

    </div>
    <div class="content footer-main flex flex-center">
      <div class="footer-content flex align-center flex-btw">
        <div class="footer-left">
          <div class="logo">
            <img src="@/assets/logo.png">
          </div>
          <div class="footer-left-desc" v-html="configs.company_desc">

          </div>
          <div class="info">
            <div class="info-item flex align-center">
              <img src="@/assets/phone.png"> <span>{{ configs.contact_phone }}</span>
            </div>
            <div class="info-item flex align-center">
              <img src="@/assets/yx.png"> <span>{{ configs.contact_email }}</span>
            </div>
            <div class="info-item flex align-center">
              <img src="@/assets/wz.png"> <span>{{ configs.contact_address }}</span>
            </div>
          </div>
        </div>
        <div class="footer-right">
          <div class="nav-list flex  flex-btw">
            <div class="nav-item" v-for="item in menuList" :key="item.id" v-show="item.id!=5">
              <div class="nav-item-title" @click="goOutsidePath(item.page_url)">{{ item.title }}</div>
              <div class="nav-item-desc" v-for="sub in item.children" :key="sub.id">
                <div class="desc-item" @click="goSubPath(item.page_url,sub.id)">{{ sub.title }}</div>
              </div>
            </div>
            <div class="nav-item code">
              <div class="nav-item-title like">关注我们</div>
              <div class="code-list flex">
                <div class="code-item flex flex-col align-center">
                  <img :src="configs.wechat_account_img">
                  <div class="code-item-title">微信公众号</div>
                </div>
                <div class="code-item flex flex-col align-center">
                  <img :src="configs.wechat_img">
                  <div class="code-item-title">抖音</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-tips">{{ configs.school_name }} <a href="https://beian.miit.gov.cn/" target="_blank">{{
        configs.icp
      }}</a> 技术支持：<a href="https://aisisoft.cn" target="_blank">艾思软件</a></div>
  </div>
</template>

<script setup>


import {config} from "@/api";
import {onBeforeMount, onMounted, ref} from "vue";
import {useRouter} from "vue-router";

const menuList = ref([])
onBeforeMount(() => {
  // if (sessionStorage.getItem("config")) {
  //   configs.value = JSON.parse(sessionStorage.getItem('config'))
  // } else {
  //   getConfig()
  // }
  getConfig()
  if (sessionStorage.getItem("menu")) {
    menuList.value = JSON.parse(sessionStorage.getItem('menu'))
  } else {
    setTimeout(function () {
      menuList.value = JSON.parse(sessionStorage.getItem('menu'))
    }, 1000)
  }


})
const router = useRouter()
//路由跳转
const goOutsidePath = (url) => {
  // router.push(url);
  const urls = router.resolve({
    path: url,
  })
  window.open(urls.href, '_blank')

}
const goSubPath = (url, id) => {
  // router.replace({
  //   path: url, query: {
  //     id: id
  //   }
  // });
  const urls = router.resolve({
    path: url,
    query: {id: id}
  })
  window.open(urls.href, '_blank')
}
// 监听路由变化

const configs = ref('')
const getConfig = () => {
  config().then(res => {
    configs.value = res.data
    sessionStorage.setItem("config", JSON.stringify(res.data))
  })
}
</script>

<style scoped lang="scss">
.footer-layout {
  margin-top: 40px;
  position: relative;
  width: 100%;
  background: #176C73;
  height: 383px;

  .bg {
    position: absolute;
    z-index: 1;
    bottom: 0;
    width: 985px;
    height: calc(100% - 50px);
    background-image: url("@/assets/footer.png");
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 auto;
  }

  .bottom-tips {
    position: absolute;
    z-index: 2;
    bottom: 0;
    width: 100%;
    height: 50px;
    text-align: center;
    background: #0A5A61;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #DBEAEB;
    line-height: 50px;

    a {
      text-decoration: none;
      color: #fff;
    }
  }
}

.footer-main {
  position: absolute;
  bottom: 0;
  height: 100%;
}

.footer-content {
  position: relative;
  z-index: 2;
  width: 100%;


  .footer-left {
    width: 30%;

    .logo {
      width: 288px;
      height: 47px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .footer-left-desc {
      padding-left: 47px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #FFFFFF;
      line-height: 26px;
      margin: 10px 0 20px;

    }

    .info {
      padding-left: 47px;

      .info-item {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #DBEAEB;
        line-height: 30px;

        img {
          margin-right: 10px;
          width: 14px;
          height: 14px;
          object-fit: cover;
        }
      }
    }
  }

  .footer-right {
    width: 60%;
    font-family: PingFang SC, PingFang SC;

    .nav-list {
      .nav-item {
        .nav-item-title {
          cursor: pointer;
          font-weight: 600;
          font-size: 16px;
          color: #FFFFFF;
          padding-bottom: 21px;

        }

        & :hover {
          cursor: pointer;
          color: #F8B62B;
          transition: 0.3s;
        }


        .nav-item-desc {
          .desc-item {
            cursor: pointer;

            font-weight: 400;
            font-size: 14px;
            line-height: 32px;
            color: #DBEAEB;

          }

          & :hover {
            cursor: pointer;
            color: #F8B62B;
            transition: 0.3s;
          }
        }

        .code-list {
          .code-item {
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #FFFFFF;
            line-height: 32px;
            margin-right: 15px;

            img {
              //margin-bottom: 23px;
              width: 100px;
              height: 100px;
            }

          }
        }
      }
    }
  }
}

@import "@/style/Footer.scss";
</style>
