<template>
  <div class="main">
    <div class="navigation flex flex-center pc">
      <div class="content flex align-center flex-btw pc">
        <div class="logo flex align-center ">
          <img src="@/assets/l.png" alt="" @click="goOutsidePath('/')">
        </div>
        <div class="nav-list flex align-center flex-btw">
<!--          <div class="nav-item">-->
<!--            <div class="flex align-center nav-txt flex-col" :class="{-->
<!--              'is-active':route.path=='/'-->
<!--            }"><span @click="goOutsidePath('/')">首页</span></div>-->
<!--          </div>-->
          <div class="nav-item flex flex-center" v-for="item in navList" :key="item.id">
            <div class="flex align-center nav-txt" :class="{
              'is-active':route.path==item.page_url
            }"><span @click="goOutsidePath(item.page_url)">{{
                item.title
              }}</span><img
                src="@/assets/nav-xl.png" v-if="item.children.length"></div>
            <div class="item-child" v-if="item.children.length">
              <div class="child-item" v-for="sub in item.children" :key="sub.id"
                   @click="goSubPath(item.page_url,sub.id)">
                {{ sub.title }}
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="phone">
      <div class="phone-navigation flex align-center flex-btw">
        <div class="phone-logo">
          <img src="@/assets/l.png">
        </div>
        <div class="open" @click="openNav=!openNav"  >
          <img src="@/assets/open.png">
        </div>
      </div>
      <div class="black" @touchmove.prevent :class="{
        'is-active':openNav
      }">
        <div class="phone-nav-list " >
          <div class="phone-nav-item">
            <div class="phone-nav-txt flex align-center flex-col" @click="goOutsidePath('/')">
              <span>首页</span>
            </div>
          </div>
          <div class="phone-nav-item" v-for="item in navList" :key="item.id">
            <div class="phone-nav-txt flex align-center flex-col">
              <span class="main-title" @click="goOutsidePath(item.page_url)">{{ item.title }}</span>
              <div class="phone-sub-list">
                <div class="phone-sub-item" v-for="sub in item.children" :key="sub.id"
                     @click="goSubPath(item.page_url,sub.id)">
                  {{ sub.title }}
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script setup>

import {onBeforeMount, onBeforeUpdate, onMounted, ref} from "vue";
import {useRouter, useRoute} from 'vue-router'
import {getMenu} from "@/api";

const navList = ref([])
const router = useRouter()
const openNav = ref(false)
const nowCheck = ref(0)
//路由跳转
const goOutsidePath = (url) => {
  // sessionStorage.setItem('nowCheck',url)
  // nowCheck.value=url
  router.push(url);
  openNav.value = false
}


const goSubPath = (url, id) => {
  router.replace({
    path: url, query: {
      id: id
    }
  });
  openNav.value = false
}
const route = useRoute()

onMounted(() => {
  // console.log(route.path)
  // const checkUrl =sessionStorage.getItem('nowCheck')
  // if(checkUrl!=null){
  //   nowCheck.value=checkUrl
  // }
  // console.log(nowCheck.value)
})
onBeforeMount(() => {
  // if (sessionStorage.getItem("menu")) {
  //   navList.value = JSON.parse(sessionStorage.getItem('menu'))
  // } else {
  //   getMenu().then(res => {
  //     navList.value = res.data
  //     sessionStorage.setItem("menu", JSON.stringify(res.data))
  //   })
  // }
  getMenu().then(res => {
    navList.value = res.data
    sessionStorage.setItem("menu", JSON.stringify(res.data))
  })
})

</script>
<style scoped lang="scss">
.main {
  width: 100%;

}

.navigation {
  width: 100%;
  height: 75px;
  background: #024B51;

  .logo {
    transition: all .2s;
    width: 290px;
    //height: 45px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      cursor: pointer;
    }

  }

  .content {
    //width: 1200px;
    //margin: 0 auto;
  }

  .nav-list {
    transition: all .2s;

    .nav-item {
      width: 140px;
      position: relative;

      .nav-txt {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #FFFFFF;

        &:hover {
          cursor: pointer;
          color: #F8B62B;
          transition: 0.3s;

        }
      }

      .is-active {
        cursor: pointer;
        color: #F8B62B;
        transition: 0.3s;
      }

      img {
        width: 10px;
        height: 5px;
        margin-left: 10px;
      }

      .item-child {
        position: absolute;
        top: calc(100% + 20px);
        z-index: 66;
        opacity: 0;
        visibility: hidden;
        background: #2a2a2a;
        transition: 0.3s;


        .child-item {
          width: 140px;
          text-align: center;
          height: 40px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #333333;
          line-height: 40px;
          background: #FFFFFF;
          cursor: pointer;

          &:hover {
            color: #fff;
            background: #024B51;
            transition: 0.5s;

          }

        }
      }

      &:hover {
        .item-child {
          opacity: 1;
          visibility: visible;

        }
      }
    }
  }
}

.phone {
  position: relative;
  display: none;
  width: 100%;

  .phone-navigation {
    width: calc(100% - 40px);
    padding: 0 20px;
    background: #176C73;
    height: 50px;

    .phone-logo {
      width: 170px;
      height: 30px;

      img {
        width: 100%;
      }
    }

    .open {
      width: 25px;
      height: 25px;

      img {
        width: 100%;
      }


    }
  }

  .black {
    width: 100%;
    position: absolute;
    z-index: 99;
    top: 50px;
    height: 100vh;
    overflow: hidden;
    background: rgba(0,0,0,.5);
    max-height: 0;
    overflow: hidden;
    transition: max-height .4s;
  }

  .phone-nav-list {
    background: #fff;

    width: 100%;


    .phone-nav-item {
      //height: 40px;
      //line-height: 40px;
      line-height: 50px;

      .main-title {
        //width: 100%;
        text-align: center;
      }

      border-bottom: 1px solid #F0F0F0;

      & :hover {
        //.main-title {
        background: #176C73;
        color: #fff;
        //}


        .phone-sub-list {
          max-height: 1000px;
        }
      }

      .phone-sub-list {
        background: #f0f0f0;
        color: #000;
        max-height: 0;
        overflow: hidden;
        width: 100%;
        transition: .5s;
        text-align: center;

        .phone-sub-item {
          //height: 40px;
          line-height: 50px;
          border-bottom: 1px solid #F0F0F0;

          & :hover {
            background: #176C73;
            color: #fff;
          }
        }
      }
    }
  }

  .is-active {
    max-height: 1000px;
  }

}

@import "@/style/Navigation.scss";

</style>
