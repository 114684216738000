
import axios from "@/request";


// 获取目录
export const getMenu = (data) => axios.post('/api/Index/getMenu', data)

// 获取首页数据
export const getIndexData = (data) => axios.post('/api/Index/index', data)

// 获取老师列表
export const getTeacherList = (data) => axios.post('/api/Index/teacher', data)

// 获取文章详情
export const articleDetail = (data) => axios.post('/api/Index/articleDetail', data)

// 获取工作机会列表
export const recruit = (data) => axios.post('/api/Index/recruit', data)

// 获取社团列表
export const clubsList = (data) => axios.post('/api/Index/clubsList', data)

// 获取党建工作数据列表
export const buildingWorkList = (data) => axios.post('/api/Index/buildingWorkList', data)

// 获取配置文件接口
export const config = (data) => axios.post('/api/Index/config', data)

// 获取页面图片
export const configImg = (data) => axios.post('/api/Index/configImg', data)

// 获取党建工作详情
export const buildingWorkDetail = (data) => axios.post('/api/Index/buildingWorkDetail', data)

// 获取社团详情接口
export const clubsDetail = (data) => axios.post('/api/Index/clubsDetail', data)

// 获取国华动态列表
export const dynamicLists = (data) => axios.post('/api/Index/dynamicLists', data)

// 招生详情
export const getEnrollment = (data) => axios.post('/api/Index/getEnrollment', data)





